@import '@core/styles/mixins/spinner';

.rm-Changelog {
  --ChangelogPage-post-width: var(--hub-main-max-width, 80%);

  padding: 30px 31px 0; // 31px to match other pages

  &_loading {
    @include spinner;
  }
}
