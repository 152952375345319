@import '~@core/styles/vars';

.Changelog {
  &Icon {
    border-radius: var(--xs);
    box-shadow: var(--box-shadow-pill);
    color: var(--white);
    font-size: var(--icon-md);
    margin-right: var(--sm);
    padding: 4px;

    &_added {
      background: linear-gradient(180deg, lighten($green, 10%) 0, var(--green) 100%);
    }

    &_fixed {
      background: linear-gradient(180deg, lighten($blue, 10%) 0, var(--blue) 100%);
    }

    &_improved {
      background: linear-gradient(180deg, lighten($purple, 10%) 0, var(--purple) 100%);
    }

    &_deprecated {
      background: linear-gradient(180deg, lighten($yellow, 10%) 0, var(--yellow) 100%);
    }

    &_removed {
      background: linear-gradient(180deg, lighten($red, 10%) 0, var(--red) 100%);
    }
  }

  &List {
    &_empty {
      align-items: center;
      box-sizing: border-box;
      color: var(--color-text-muted);
      display: flex;
      font-size: 20px;
      font-style: italic;
      height: calc(100vh - 100px);
      justify-content: center;
      min-height: 3em;
      padding: var(--md);
      text-align: center;
    }

    &_pagination {
      margin: auto;
      max-width: var(--ChangelogPage-post-width);
      padding: var(--md);
    }
  }

  &Page {
    align-items: flex-start;
    display: grid;

    @media (min-width: $ipad-mini-portrait + 1) {
      grid-template-columns: 1fr 5fr 1fr;
    }

    &_back {
      width: 190px;

      @media (max-width: $ipad-mini-portrait) {
        margin: 0 auto var(--md);
        width: auto;
      }
    }

    &_list {
      $list: &;

      display: flex;
      flex-direction: row;
      gap: var(--lg);
      justify-content: center;
      padding-top: 30px;

      #{$list}-date {
        color: var(--color-text-default);
        flex: 0 0 190px;
        padding-top: 0.4em;
        position: sticky;
        top: var(--md);

        @media (max-width: $ipad-mini-portrait) {
          align-items: flex-start;
          display: flex;
          flex: none;
          flex-direction: column;
          gap: var(--xs);
          padding-bottom: var(--sm);
          padding-top: 0;
          position: initial;
        }
      }

      &-type {
        &#{$list}-type {
          font-size: 12px;
          font-weight: var(--font-weight);
          text-transform: uppercase;

          @media (max-width: $ipad-mini-portrait) {
            margin-bottom: 0;
            order: -1;
          }
        }
      }

      &-icon {
        font-size: inherit;
        margin-right: var(--xs);
        padding: 2px;
      }

      &-post {
        &#{$list}-post {
          margin: 0;
        }
      }

      @media (max-width: $ipad-mini-portrait) {
        flex-direction: column;
        gap: 0;
      }
    }
  }

  &Post {
    margin: 0 auto;
    max-width: var(--ChangelogPage-post-width);
    overflow: hidden;
    width: 100%;

    &Excerpt {
      border-bottom: 1px solid var(--color-skeleton);
      max-width: var(--ChangelogPage-post-width);
      padding-top: 30px;
    }

    &_back {
      background-color: transparent;
      border: 0;
      color: var(--color-link-primary);
      margin: 4px 0;

      &:hover,
      &:active {
        background-color: transparent;
        color: var(--color-link-primary-darken-10);
      }

      &-icon {
        opacity: 0.5;
        padding-right: var(--xs);
      }
    }

    &_date {
      font-size: 14px;
      padding-bottom: var(--md);
    }

    &_header {
      border-bottom: 1px solid var(--color-skeleton);
      margin-bottom: var(--md);
      padding-bottom: var(--sm);
    }

    &_text {
      margin-bottom: 30px;
    }

    &_title {
      font-size: 1.9em;
      font-weight: var(--font-weight);
      line-height: 1.2;
      margin: var(--sm) 0;

      &:first-child {
        margin-bottom: 0;
        margin-top: 0;
      }

      &excerpt {
        color: var(--color-link-primary);
      }
    }

    &_type {
      align-items: center;
      color: var(--color-text-minimum);
      display: flex;
      font-size: 14px;
      font-weight: var(--font-weight-bold);
      margin-bottom: var(--sm);
      text-transform: capitalize;
    }

    &-author {
      color: var(--color-text-muted);
      font-size: var(--markdown-font-size);
      font-style: normal;
    }
  }
}
