@import './dark-mode';

@mixin spinner {
  --Spinner-ring: var(--gray80);
  --Spinner-element: var(--gray90);

  @include dark-mode {
    --Spinner-ring: var(--gray20);
    --Spinner-element: var(--gray30);
  }

  /* stylelint-disable-next-line order/order */
  position: relative;

  @keyframes spinner {
    0% {
      transform: rotate(0deg) scale(1);
    }

    100% {
      transform: rotate(360deg) scale(1);
    }
  }

  &::before {
    background: rgba(var(--color-bg-page-rgb), 0.75);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
  }

  &::after {
    animation: spinner 1s infinite linear;
    border: 0.25rem solid var(--Spinner-element);
    border-radius: 50%;
    border-top-color: var(--Spinner-ring);
    content: '';
    height: 40px;
    left: 50%;
    pointer-events: none;
    position: fixed;
    top: 50%;
    width: 40px;
    z-index: 99;
  }
}
